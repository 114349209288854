import Image from "next/image";
import React from "react";

interface EmptyTabesPlaceholderPropType {
  props: {
    Icon: {
      link: string;
      height: number;
      width: number;
    };
    Header?: string;
    description?: string;
  };
}

const EmptyTabesPlaceholder = ({
  props: { Header, Icon, description },
}: EmptyTabesPlaceholderPropType) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Image
        src={Icon.link}
        alt="Icon"
        height={Icon.height}
        width={Icon.width}
        className=""
      />
      {Header ? (
        <div className="lg:text-2xl text-[18px] lg:mt-4 mt-2 font-medium text-secondary-Black">
          {Header}
        </div>
      ) : (
        ""
      )}

      {description && (
        <div className="lg:text-xl text-base lg:mt-4 mt-2 text-primary-Black opacity-[80%] max-w-[485px] font-normal flex justify-center items-center text-center">
          {description}
        </div>
      )}
    </div>
  );
};

export default EmptyTabesPlaceholder;

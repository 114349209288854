import axios from "@/utils/axios";
import {
  AxiosError,
  followersResponse,
  tokensResponse,
  tokenType,
  tradesResponse,
  userType,
} from "@/types/global";
import { getErrorMessage } from "@/utils/helper";
import { toast } from "react-toastify";

export async function getUserDetails(
  id: string,
): Promise<userType | undefined> {
  try {
    const response = await axios.get(`/users/${id}/profile`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getUserLeaderboard() {
  try {
    const response = await axios.get(`/winner-circle`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function searchUsers(q = "") {
  try {
    const response = await axios.get(`/users/search?query=` + q);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getUserTokens(
  id: string,
  page = 1,
): Promise<tokensResponse | undefined> {
  try {
    const response = await axios.get(`/users/${id}/tokens?page=` + page);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getUserTradedTokens(
  id: string,
  page = 1,
): Promise<tradesResponse | undefined> {
  try {
    const response = await axios.get(`/users/${id}/token-trades?page=` + page);
    return response.data || [];
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getUserFollowers(
  id: string,
  page = 1,
): Promise<followersResponse | undefined> {
  try {
    const response = await axios.get(`/users/${id}/followers?page=` + page);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getUserFollowings(
  id: string,
  page = 1,
): Promise<followersResponse | undefined> {
  try {
    const response = await axios.get(`/users/${id}/followings?page=` + page);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function followUser(id: string) {
  try {
    const response = await axios.post(`/follows/${id}`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function unFollowUser(id: string) {
  try {
    const response = await axios.delete(`/follows/${id}`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

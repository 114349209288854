import { twMerge } from "tailwind-merge";
import { clampPercentage } from "@/utils/helper";
export default function ProgressBar({
  width,
  height,
  className,
  progressWidth,
  ProgressBarClassName,
}: {
  onClick?: () => void;
  className?: string;
  ProgressBarClassName?: string;
  width: string | number;
  progressWidth: string | number;
  height: string | number;
}) {
  return (
    <div
      className={twMerge(
        `w-[${width}] overflow-hidden bg-[#939FB8] rounded-full h-1`,
        className ?? "",
      )}
      style={{ height: `${height}` }}
    >
      <div
        className={twMerge(
          `bg-[#48C774]  rounded-full  h-1`,
          ProgressBarClassName ?? "",
        )}
        style={{
          width: `${clampPercentage(parseFloat(progressWidth.toString()))}%`,
          height: `${height}`,
        }}
      ></div>
    </div>
  );
}
